import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '../avatar/avatar.module';
import { ModalAlertModule } from '../modal-alert/modal-alert.module';
import { MenuDropdownComponent } from './menu-dropdown.component';

@NgModule({
  declarations: [MenuDropdownComponent],
  imports: [
    CommonModule,
    RouterModule,
    AvatarModule,
    ModalAlertModule,
    MatMenuModule,
  ],
  exports: [MenuDropdownComponent],
})
export class MenuDropdownModule {}
