export enum Acoes {

}

export enum Funcionalidades {
    
}

export class RoleFuncionalidadeAcaoPerfilQuery {
    constructor(
        public idFuncionalidade: number,
        public idAcao: number,
        public idUsuario: string
    ) { }
}
