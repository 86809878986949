import { Injectable } from '@angular/core';
import { ToastService } from '../../components/toast/_services/toast.service';
import { NO_CONNECTON_MESSAGE } from '../../constants/global.contants';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(private _toastService: ToastService) {}

  error() {
    this._toastService.connection(NO_CONNECTON_MESSAGE);
  }
}
