<div *ngIf="modalAtivo">
  <div class="aviva-modal-background"></div>
  <div class="aviva-modal">
    <div class="titulo">Política de Privacidade e Termos de Uso</div>
    <div class="descricao">
      Declaro ter lido e aceito a
      <a
        target="_blank"
        href="https://hmlsite.cdr.net.br/politica-de-privacidade/"
        >Política de Privacidade</a
      >
      e os
      <a target="_blank" href="https://hmlsite.cdr.net.br/termos-de-uso/"
        >Termos de Uso</a
      >
      que estão publicados no site da CDR.
    </div>
    <div class="containerBotao">
      <button
        type="button"
        class="aviva-button-primary-200-low buttonsResponsive"
        (click)="recusarPolitica()"
      >
        Não Aceitar
      </button>
      <button
        type="button"
        class="aviva-button-primary-200-high buttonsResponsive"
        (click)="aceitarPolitica()"
      >
        Aceitar
      </button>
    </div>
    <div class="rodape">
      A não aceitação da Política de Privacidade e/ou dos Termos de Uso impede a
      continuidade da navegação. Você será redirecionado para a página de login.
    </div>
  </div>
</div>
