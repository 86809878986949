<div>
  <app-avatar [name]="nome" [matMenuTriggerFor]="menuDropDown"></app-avatar>
</div>
<mat-menu #menuDropDown="matMenu" style="margin-top: 20px">
  <div class="menuDropdown">
    <div class="info-pessoais">
      <app-avatar [name]="nome" class="app-avatar"></app-avatar>
      <div>
        <p class="info-nome-usuario">
          <b> {{ nome }} </b>
        </p>
        <p class="overflowText info-entidade">
          {{ clienteVinculado }}
        </p>
      </div>
    </div>
    <button mat-menu-item (click)="redirectMinhaConta()">Minha Conta</button>

    <button
      mat-menu-item
      (click)="redirectDropDown('https://cdr.net.br/termos-de-uso/')"
    >
      Termos de Uso
    </button>
    <button
      mat-menu-item
      (click)="redirectDropDown('https://cdr.net.br/politica-de-privacidade/')"
    >
      Política de Privacidade
    </button>
    <div class="info-data">
      Logado desde: {{ dataAtual }} <span> • </span> {{ horaAtual }}
    </div>
    <div (click)="confirmarLogout()" class="info-sair">
      Sair <i class="aviva-feather-log-out"></i>
    </div>
  </div>
</mat-menu>

<app-modal-alert
  *ngIf="exibirModalConfirmacao"
  [options]="optionsModal"
  (eventButtonLeft)="closeModal()"
  (eventButtonRight)="logout()"
>
</app-modal-alert>
