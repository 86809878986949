import { Injectable, SimpleChange } from '@angular/core';

import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public exibirMenuSubject = new Subject<boolean>();
  public exibirMenu: boolean = true;
  public limiteMobile: number = 769;

  constructor(private _storageService: StorageService) {}

  private data = new BehaviorSubject('default data');
  data$ = this.data.asObservable();

  handleMenu(open = false) {
    this.exibirMenu = open;
    this.exibirMenuSubject.next(open);
  }
  watchMenu(): Observable<boolean> {
    return this.exibirMenuSubject.asObservable();
  }

  changeData(data: string) {
    this.data.next(data);
  }

  public downloadBase64(arquivo) {
    let doc = '';
    if (arquivo.conteudo.startsWith('data')) {
      doc = arquivo.conteudo;
    } else {
      doc = 'data:' + arquivo.tipo + ';base64,' + arquivo.conteudo;
    }
    let mywindow = window.open('', '_blank');
    let element: any = document.createElement('a');
    element.setAttribute('href', '' + encodeURI(doc));
    element.setAttribute('download', arquivo.nome);
    mywindow.document.write(element);
    element.click();
    mywindow.close();
  }

  public downloadArquivo(
    data: any,

    tipo: string,

    nome: string,

    formato?: string
  ) {
    const blob = new Blob([data], { type: tipo });

    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement('a');

    anchor.download = `${nome}${formato ? '.' + formato.toLowerCase() : ''}`;

    anchor.href = url;

    anchor.click();

    window.URL.revokeObjectURL(url);
  }

  public downloadArquivoLink(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  public formataValorDecimal(valor: string, casasDecimais: number): string {
    const valorSplit = valor.split(',');
    const valorAntesVirgula = valorSplit[0];
    let valorDepoisVirgula = '00';

    if (valorSplit.length > 1) {
      valorDepoisVirgula = valorSplit[1].substring(0, casasDecimais);
    }

    if (valorDepoisVirgula.length < casasDecimais) {
      const casasDecimaisFaltando = casasDecimais - valorDepoisVirgula.length;
      for (let i = 0; i < casasDecimaisFaltando; i++) {
        valorDepoisVirgula += '0';
      }
    }

    return `${valorAntesVirgula},${valorDepoisVirgula}`;
  }

  public obterDataAtual(): string {
    const dia = new Date().getDate();
    const dataAtual = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      dia
    )
      .toISOString()
      .substring(0, 10);
    return dataAtual;
  }

  public comparaData(date1: any, date2: any): boolean {
    let aux1 = moment(date1).format();
    let aux2 = moment(date2).format();

    const d1 = moment(aux1, 'YYYY-MM-DD');
    const d2 = moment(aux2, 'YYYY-MM-DD');

    if (moment(d1).isSameOrAfter(d2)) return false;
    else return true;
  }

  public validarData(date: any) {
    let aux = moment(date).format();

    const dataFormat = moment(aux, 'YYYY-MM-DD');

    if (moment(dataFormat).isValid()) {
      return true;
    } else return false;
  }

  calcularIdade(date: any) {
    let aux = moment(date).format();

    const dataFormat = moment(aux, 'YYYY-MM-DD');

    let idade = moment().diff(date, 'years', true);
    return idade;
  }

  public datasIguais(date1: any, date2: any): boolean {
    let aux1 = moment(date1).format();
    let aux2 = moment(date2).format();

    const d1 = moment(aux1, 'YYYY-MM-DD');
    const d2 = moment(aux2, 'YYYY-MM-DD');

    if (moment(d1).isSame(d2)) return true;
    else return false;
  }

  public validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') {
      return false;
    }

    if (cnpj.length !== 14) {
      return false;
    }

    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) {
      return false;
    }

    return true;
  }

  public mascaraCnpj(valor) {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  public validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') {
      return false;
    }

    if (
      cpf.length != 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false;
    }

    let add = 0;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) {
      rev = 0;
    }
    if (rev != parseInt(cpf.charAt(9))) {
      return false;
    }

    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) {
      rev = 0;
    }
    if (rev != parseInt(cpf.charAt(10))) {
      return false;
    }
    return true;
  }

  public validarCNH(cnh) {
    let cnhChar = cnh.charAt(0);
    var ret;

    if (cnh.replace(/[^\d]/g, '').length === 11 && cnhChar.repeat(11) !== cnh) {
      var dsc = 0;

      for (var i = 0, j = 9, v = 0; i < 9; ++i, --j) {
        v += +(cnh.charAt(i) * j);
      }

      var vl1 = v % 11;
      if (vl1 >= 10) {
        vl1 = 0;
        dsc = 2;
      }

      for (i = 0, j = 1, v = 0; i < 9; ++i, ++j) {
        v += +(cnh.charAt(i) * j);
      }

      var x = v % 11,
        vl2 = x >= 10 ? 0 : x - dsc;

      ret = '' + vl1 + vl2 === cnh.substr(-2);
    }
    if (ret !== true) return false;

    return true;
  }

  public validarEmail(email: string): boolean {
    const pattern = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{1,3})$/;
    return pattern.test(email);
  }

  public validarURL(url: string, texto: string): boolean {
    return texto.toLowerCase().includes(url.toLowerCase());
  }

  public replaceSpecialChars(str: string) {
    str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
    str = str.replace(/[àáâãäå]/, 'a');
    str = str.replace(/[ÈÉÊË]/, 'E');
    str = str.replace(/[Ç]/, 'C');
    str = str.replace(/[ç]/, 'c');
    return str.replace(/[^a-z0-9]/gi, '');
  }

  public isMobile() {
    return window.innerWidth < this.limiteMobile;
  }

  public downloadFileById(
    endpoint: string | undefined,
    filename: string | undefined
  ) {
    fetch(`${environment.API}/${endpoint}`, {
      headers: {
        Authorization: `bearer ${this._storageService.getToken()}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = `${filename}`;
        anchor.href = url;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
  }

  public validarEmailLogin(email: string): boolean {
    const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
    return pattern.test(email);
  }
}
