import { ToastService } from './_services/toast.service';
import { Toast, ToastType } from './_models/toast.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  public toasts: Toast[] = [];

  constructor(private _toastService: ToastService, private _router: Router) {}

  ngOnInit(): void {
    this._toastService.getAlert().subscribe((toast: Toast) => {
      if (!toast || toast.message.length === 0) {
        // clear alerts when an empty alert is received
        this.toasts = [];
        return;
      }

      // add alert to array
      let toastRepetido = this.toasts.find((x) => x.message === toast.message);
      if (!toastRepetido) {
        this.toasts.push(toast);
      }

      setTimeout(() => {
        this.closeToast(toast);
      }, environment.time_toast as any);
    });
  }

  closeToast(toast: Toast) {
    this.toasts = this.toasts.filter((x) => x !== toast);
  }

  clickButton(toast: Toast) {
    if (!!toast.routeDefault && !!toast.routeAction) {
      this._router.navigate([toast.routeDefault, toast.routeAction]);
    } else if (!!toast.routeDefault) {
      this._router.navigate([toast.routeDefault]);
    }
  }

  getClass(toast: Toast): string | void {
    if (!toast) {
      return;
    }

    // return css class based on alert type
    switch (toast.type) {
      case ToastType.SUCCESS:
        return 'positive';
      case ToastType.ERROR:
        return 'negative';
      case ToastType.INFO:
        return 'informative';
      case ToastType.NEUTRAL:
        return 'neutral';
      case ToastType.ETHERNET:
        return 'negative';
    }
  }
}
