import { ErrorHandler, Injectable } from '@angular/core';
import { ConnectionService } from '../_services/connection/connection.service';
import { ToastService } from '../components/toast/_services/toast.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private _connectionService: ConnectionService,
    private _toastService: ToastService
  ) {}

  handleError(error: any): void {
    if (error?.code != 100) {
      console.error(error);
      if (error.status === 401) {
        let error =
          'Por questões de segurança a sessão foi encerrada, por favor, faça o login novamente na plataforma.';
        this._toastService.error(error);
      } else {
        this._connectionService.error();
      }
    }
  }
}
