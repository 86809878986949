//LOCAL DEFAULT
export const environment = {
  API: window['env' as any]?.['API' as any] || 'https://localhost:7105/api',
  API_AC:
    window['env' as any]?.['API_AC' as any] || 'https://localhost:44352/api',
  time_toast: window['env' as any]?.['time_toast' as any] || 15000,
  version: window['env' as any]?.['version' as any] || '1.0.0.0',
  name: window['env' as any]?.['name' as any] || 'DEV',
};
//QA DEFAULT
// export const environment = {
//   API: window["env" as any]["API" as any] || 'https://localhost:5001/api',
//   API_AC: window["env" as any]["API_AC" as any] || 'https://localhost:44352/api',
//   time_toast: window["env" as any]["time_toast" as any] || 5000,
//   version: window["env" as any]["version" as any]  || "1.0.0.0",
//   name: window["env" as any]["name" as any] || "PROD",
// };

//HML DEFAULT
// export const environment = {
//   API: window["env" as any]["API" as any] || 'https://localhost:5001/api',
//   API_AC: window["env" as any]["API_AC" as any] || 'https://localhost:44352/api',
//   time_toast: window["env" as any]["time_toast" as any] || 5000,
//   version: window["env" as any]["version" as any]  || "1.0.0.0",
//   name: window["env" as any]["name" as any] || "PROD",
// };

//PROD DEFAULT
// export const environment = {
//   API: window["env" as any]["API" as any] || 'https://localhost:5001/api',
//   API_AC: window["env" as any]["API_AC" as any] || 'https://localhost:44352/api',
//   time_toast: window["env" as any]["time_toast" as any] || 5000,
//   version: window["env" as any]["version" as any]  || "1.0.0.0",
//   name: window["env" as any]["name" as any] || "PROD",
// };
