import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HttpUtils {
  private FraseService = new BehaviorSubject<string>('Carregando');
  Frase = this.FraseService.asObservable();

  private HideLoadingService = new BehaviorSubject<boolean>(true);
  HideLoading = this.HideLoadingService.asObservable();

  private HideLoadingNotificationService = new BehaviorSubject<boolean>(true);
  HideLoadingNotification = this.HideLoadingNotificationService.asObservable();

  private DesabilitaLoadingGlobal = new BehaviorSubject<boolean>(false);
  DesabilitaLoading = this.DesabilitaLoadingGlobal.asObservable();

  constructor() {}

  forceLoadingShow(frase = 'Carregando') {
    this.HideLoadingService.next(true);
    this.FraseService.next(frase);
  }
  forceLoadingHide() {
    this.HideLoadingService.next(false);
    this.FraseService.next('Carregando');
  }
  forceLoadingUpdateHide(hide: boolean) {
    this.HideLoadingService.next(hide);
  }

  forceLoadingNotificationShow(frase = 'Carregando') {
    this.HideLoadingNotificationService.next(true);
    this.FraseService.next(frase);
  }
  forceLoadingNotificationHide() {
    this.HideLoadingNotificationService.next(false);
    this.FraseService.next('Carregando');
  }
  forceLoadingNotificationUpdateHide(hide: boolean) {
    this.HideLoadingNotificationService.next(hide);
  }
  desabilitaLoadingGlobal(status: boolean) {
    this.DesabilitaLoadingGlobal.next(status);
  }
}
