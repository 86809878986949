import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { eLogAcoes, eLogTelas } from '../../guards/role/_models/role.model';
import { TiposClienteConstant } from '../../_models/tipo-cliente.models';
import { LogService } from '../../_services/log/log.service';
import { SharedService } from '../../_services/shared/shared.service';
import { StorageService } from '../../_services/storage/storage.service';
import { ModalTermosUsoPoliticaPrivacidadeService } from './services/modal-termos-uso-politica-privacidade.service';

@Component({
  selector: 'modal-termos-uso-politica-privacidade',
  templateUrl: './modal-termos-uso-politica-privacidade.component.html',
  styleUrls: ['./modal-termos-uso-politica-privacidade.component.scss'],
})
export class ModalTermosUsoPoliticaPrivacidadeComponent implements OnInit {
  constructor(
    private _modalService: ModalTermosUsoPoliticaPrivacidadeService,
    private _router: Router,
    public _serviceStorage: StorageService,
    public _serviceShared: SharedService,
    private _logService: LogService,
    private _app: AppComponent
  ) {}
  modalAtivo = false;

  ngOnInit(): void {
    this._modalService.getModal().subscribe((value) => {
      this.modalAtivo = value;
    });

    if (this._serviceStorage.getToken() && !this._app.exibirControles) {
      let request: any = {
        idTela: eLogTelas.TermosDeUsoEPoliticaDePrivacidade,
        idAcao: eLogAcoes.Acessar,
      };
      this._logService.salvar(request).subscribe(
        (res: any) => {},
        (errors) => {}
      );
    }
  }
  recusarPolitica() {
    this._modalService.setPoliticaDePrivacidade(false).subscribe(() => {
      this.logout();
      this._modalService.setModal(false);
    });
  }
  aceitarPolitica() {
    this._modalService.setPoliticaDePrivacidade(true).subscribe((value) => {
      this._modalService.setModal(false);
    });
  }

  logout() {
    const usuarioTipoCliente: number | null =
      !!this._serviceStorage.getTipoCliente()
        ? Number(this._serviceStorage.getTipoCliente())
        : 1;
    const rotaLogin: string = `/sign-in/${
      TiposClienteConstant.find((item) => item.id === usuarioTipoCliente).url
    }`;

    this._serviceShared.handleMenu(true);

    this._router.navigate([rotaLogin]);
  }
}
