import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from './core/_services/shared/shared.service';
import { ModalAlertOptions } from './core/components/modal-alert/models/modal-alert.model';
import { BnNgIdleService } from 'bn-ng-idle';
import { StorageService } from './core/_services/storage/storage.service';
import { ToastService } from './core/components/toast/_services/toast.service';
import { ModalTermosUsoPoliticaPrivacidadeService } from './core/components/modal-termos-uso-politica-privacidade/services/modal-termos-uso-politica-privacidade.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public exibirControles: boolean = false;
  public online: boolean;
  public optionsModalInatividade: ModalAlertOptions = new ModalAlertOptions();
  public optionsModalLogOff: ModalAlertOptions = new ModalAlertOptions();
  public showModalInatividade: boolean = false;
  public showModalLogOff: boolean = false;

  constructor(
    private _router: Router,
    public _serviceShared: SharedService,
    public _serviceStorage: StorageService,
    private bnIdle: BnNgIdleService,
    private _modalTermos: ModalTermosUsoPoliticaPrivacidadeService
  ) {
    _router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.includes('/sign-in') ||
          event.url.includes('/esqueci-senha') ||
          event.url.includes('/definir-senha') ||
          event.url.includes('/cadastrar-senha') ||
          event.urlAfterRedirects.includes('/erros')
        ) {
          this.exibirControles = false;
        } else {
          this.exibirControles = true;
        }
      }
    });

    // INATIVIDADE
    // this.bnIdle.startWatching(180).subscribe((inatividade) => { //3 minutos
    //   if (inatividade && this._serviceStorage.getUser() != null) {
    //     this.optionsModalInatividade.title = 'Atenção!';
    //     this.optionsModalInatividade.message = 'Você está inativo há 3 minutos. Caso o portal não seja utilizado nos próximos 2 minutos, seu login será finalizado.';
    //     this.optionsModalInatividade.buttonRight = 'Entendi';
    //     this.showModalInatividade = true;

    //     setTimeout(() => {
    //       if(this.showModalInatividade == true) {
    //         this.optionsModalLogOff.title = 'Atenção!';
    //         this.optionsModalLogOff.message = 'Identificamos que você ficou inativo por mais de 5 minutos e, por motivos de segurança encerramos seu acesso. Para retornar faça o login novamente.';
    //         this.optionsModalLogOff.buttonRight = 'Entendi';
    //         this.showModalInatividade = false;
    //         this.showModalLogOff = true;
    //       }
    //     },120000);
    //   }
    // });
  }
  ngAfterViewInit() {
    if (
      !this._serviceStorage.getTermoDeUso() &&
      this._serviceStorage.getToken()
    ) {
      this._modalTermos.setModal(true);
    }
    this._serviceStorage.aplicarWhiteLabel();
  }

  public closeModal() {
    this.showModalInatividade = false;
    this.showModalLogOff = false;
  }

  public redirect() {
    this.closeModal();
    this._router.navigate(['/sign-in']);
  }

  public isMobile(): boolean {
    return window.innerWidth <= 768;
  }
}
