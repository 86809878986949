import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { StorageService } from '../storage/storage.service';

import { BaseResponse } from '../../_models/response.model';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private http: HttpClient,
    private _serviceStorage: StorageService
  ) {}

  salvar(data) {
    let request = {
      idTela: data.idTela,
      idAcao: data.idAcao,
    };

    return this.http
      .post(`${environment.API}/log/salvar`, request)
      .pipe(map((response: BaseResponse) => response.data));
  }
}
