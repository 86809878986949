import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BnNgIdleService } from 'bn-ng-idle';
import { IConfig } from 'ngx-mask';
import { getPortuguesPaginatorIntl } from 'src/paginator-translate';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './core/components/footer/footer.module';
import { HeaderModule } from './core/components/header/header.module';
import { LoadingModule } from './core/components/loading/loading.module';
import { HttpListener } from './core/components/loading/_services/http-listener/http-listener.service';
import { HttpStatus } from './core/components/loading/_services/http-status/http-status.service';
import { HttpUtils } from './core/components/loading/_services/http-utils/http-utils.service';
import { MenuLateralModule } from './core/components/menu-lateral/menu-lateral.module';
import { ModalAlertModule } from './core/components/modal-alert/modal-alert.module';
import { ModalTermosUsoPoliticaPrivacidadeComponent } from './core/components/modal-termos-uso-politica-privacidade/modal-termos-uso-politica-privacidade.component';
import { ProcessFileComponent } from './core/components/process-file/process-file.component';
import { ToastModule } from './core/components/toast/toast.module';
import { GlobalErrorHandler } from './core/handlers/globalErrorHandler';
import { Interceptor } from './core/interceptors/interceptor-http';
import { UsuarioEffects } from './modules/operacional/access-control/usuario/_state/usuario.effects';
import { usuarioReducer } from './modules/operacional/access-control/usuario/_state/usuario.reducer';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    ProcessFileComponent,
    ModalTermosUsoPoliticaPrivacidadeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    Interceptor,
    HeaderModule,
    MenuLateralModule,
    FooterModule,
    LoadingModule,
    NoopAnimationsModule,
    ModalAlertModule,
    ToastModule,
    MatSnackBarModule,
    StoreModule.forRoot({
      usuarios: usuarioReducer,
    }),
    EffectsModule.forRoot([UsuarioEffects]),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpListener,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: getPortuguesPaginatorIntl() },
    { provide: BnNgIdleService },

    HttpStatus, //
    HttpUtils,
    CurrencyPipe,
    DatePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],

  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
