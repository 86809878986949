import { StorageService } from './../../_services/storage/storage.service';
import { SharedService } from './../../_services/shared/shared.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
  ModalAlertOptions,
  TypeModalAlert,
} from 'src/app/core/components/modal-alert/models/modal-alert.model';
import { TiposClienteConstant } from '../../_models/tipo-cliente.models';
import { RoleService } from '../../_services/role/role.service';
import {
  eAcoes,
  eFuncionalidades,
  RoleFuncionalidadeAcaoQuery,
} from '../../guards/role/_models/role.model';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss'],
})
export class MenuLateralComponent implements OnInit {
  public modulos: any = [];
  public menus: any = [];
  public nomeModulo: string;
  public iconeModulo: string;
  public pagina: string;
  public exibirListaMenus: boolean = false;
  public optionsModal: ModalAlertOptions = new ModalAlertOptions();
  public exibirModalConfirmacao: boolean = false;
  public logoCliente: any;

  constructor(
    public _serviceShared: SharedService,
    public _serviceStorage: StorageService,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        const url = event.urlAfterRedirects;
        this.pagina = url;
      }
    });
  }

  ngOnInit(): void {
    this.pagina = this._router.url;
    this.carregarMenus();
    this.logoCliente = this.carregarLogo();
  }

  mudarMenu() {
    this._serviceShared.handleMenu(!this._serviceShared.exibirMenu);
  }

  mudarSubMenu(menu) {
    if (this._serviceShared.exibirMenu && menu.aberto) {
      menu.aberto = true;
    } else {
      if (menu.menus.length > 0) {
        menu.aberto = !menu.aberto;
      } else {
        menu.aberto = true;
      }
    }
    this._serviceShared.handleMenu(false);
  }

  getRotaHome() {
    const tipoClinete = this._serviceStorage.getTipoCliente();
    if (tipoClinete && Number(tipoClinete) == 8) {
      return this._router.navigate(['operacional/catalogo-comercial']);
    } else {
      return this._router.navigate(['/operacional/home']);
    }
  }
  logout() {
    const usuarioTipoCliente: number | null =
      !!this._serviceStorage.getTipoCliente()
        ? Number(this._serviceStorage.getTipoCliente())
        : 1;
    const rotaLogin: string = `/sign-in/${
      TiposClienteConstant.find((item) => item.id === usuarioTipoCliente).url
    }`;
    this._router.navigate([rotaLogin]);

    this._serviceShared.handleMenu(true);
  }

  confirmarLogout() {
    this.gerarModalConfirmacao();
  }

  closeModal() {
    this.exibirModalConfirmacao = false;
  }

  gerarModalConfirmacao() {
    this.optionsModal.title = 'Sair do sistema mobile';
    this.optionsModal.message = 'Tem certeza que deseja sair do sistema?';
    this.optionsModal.buttonRight = 'Sair';
    this.optionsModal.buttonLeft = 'Cancelar';
    this.optionsModal.type = TypeModalAlert.NEUTRAL;
    this.optionsModal.icon = 'aviva-feather-log-out';

    this.exibirModalConfirmacao = true;
  }

  carregarMenus(): void {
    let m: any = this._serviceStorage.getMenus();

    // 1º Nível
    this.menus = m.filter(
      (x) => x.idMenuPai == null || x.idMenuPai == undefined
    );

    // 2º Nível
    this.menus.forEach((menu) => {
      m.filter((x) => x.idMenuPai == menu.id).forEach((element) => {
        menu.menus.push(element);
      });

      menu.menus.sort((a, b) => a.ordem - b.ordem);

      // 3º Nível
      menu.menus.forEach((menu) => {
        m.filter((x) => x.idMenuPai == menu.id).forEach((submenu) => {
          menu.exibir = false;
          menu.menus.push(submenu);
        });
      });
    });

    this.menus.forEach((element) => {
      if (element.link == undefined && element.menus.length == 0) {
        const index = this.menus.indexOf(element);
        if (index > -1) {
          this.menus.splice(index, 1);
        }
      }
    });
    this.verificarPermissaoConsultarVendas();
  }
  verificarPermissaoConsultarVendas() {
    const permissaoConsultarVenda =
      this._storageService.verificarFuncionalidade(
        new RoleFuncionalidadeAcaoQuery(
          eFuncionalidades.CadastroVenda,
          eAcoes.Consultar
        )
      );
    if (permissaoConsultarVenda) {
      this.adicionarConsultarVendas();
    } else {
      const permissaoGestaoVendas =
        this._storageService.verificarFuncionalidade(
          new RoleFuncionalidadeAcaoQuery(
            eFuncionalidades.CadastroVenda,
            eAcoes.GestaoVendas
          )
        );
      if (permissaoGestaoVendas) {
        this.adicionarConsultarVendas();
      }
    }
  }
  adicionarConsultarVendas() {
    let menuConsultarVenda = {
      id: 0,
      descricao: 'Consultar Venda',
      icone: '',
      link: '/consultar-venda',
      ordem: 4,
      exibir: true,
      idMenuPai: eFuncionalidades.CadastroVenda,
      menus: [],
    };
    let posicaoVendas = this.menus.findIndex(
      (item) => item.id == eFuncionalidades.CadastroVenda
    );
    this.menus[posicaoVendas].menus.push(menuConsultarVenda);
    this.menus[posicaoVendas].menus.sort((a, b) => a.ordem - b.ordem);
  }
  rotaAtual(submenus) {
    if (submenus.length > 0) {
      if (
        submenus.find(
          (x) => x.link == '/' + this.pagina.split('?').shift().split('/').pop()
        ) !== undefined
      ) {
        return true;
      } else {
        let res = false;

        if (
          submenus.find(
            (x) => this.pagina.split('?').shift().search(x.link) >= 0
          ) !== undefined
        ) {
          res = true;
        }

        submenus.forEach((element) => {
          if (
            element.menus.find(
              (x) =>
                x.link == '/' + this.pagina.split('?').shift().split('/').pop()
            ) !== undefined
          ) {
            element.exibir = true;
            res = true;
          } else {
            if (
              element.menus.find(
                (x) => this.pagina.split('?').shift().search(x.link) >= 0
              ) !== undefined
            ) {
              element.exibir = true;
              res = true;
            }
          }
        });

        return res;
      }
    } else {
      if (submenus.link) {
        if (
          submenus.link ==
          '/' + this.pagina.split('?').shift().split('/')?.[2]
        ) {
          return true;
        }

        if (
          this.pagina.replace('/operacional', '').split('?').shift() ==
          submenus.link
        ) {
          return true;
        }

        let pagina = this.pagina.replace('/operacional', '').split('?').pop();
        if (
          pagina.startsWith(submenus.link) &&
          (pagina.endsWith('adicionar') ||
            pagina.search('editar') >= 0 ||
            pagina.search('consultar') >= 0)
        ) {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  carregarLogo(): string {
    const logoWhiteLabel = this._storageService.LOGO_BASE64();
    if (!!logoWhiteLabel && logoWhiteLabel !== 'undefined') {
      return logoWhiteLabel.replace(/['"]/g, '');
    } else {
      return '/assets/logos/logo_cdr_colorido.svg';
    }
  }
}
