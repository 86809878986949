export class UsuarioStorage {
  constructor(
    public ID_USUARIO: string,
    public NOME: string,
    public EMAIL: string,
    public ID_PERFIL: string,
    public ID_CLIENTE: string,
    public TIPO_CLIENTE: string,
    public TOKEN: string,
    public MENUS: any,
    public FUNCIONALIDADES: any,
    public COR_PRIMARIA?: any,
    public COR_SECUNDARIA?: any,
    public LOGO_BASE64?: any,
    public TERMOS_USO_ACEITO?: boolean,
    public RAZAOSOCIALCLIENTEVINCULADO?: string,
    public DATAATUAL?: string,
    public HORAATUAL?: string
  ) {}
}
