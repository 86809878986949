<aside>
  <button
    class="button-icon aviva-margin-right-xxs onlyMobile"
    *ngIf="_serviceShared.exibirMenu"
    (click)="mudarMenu()"
  >
    <img src="/assets/icons/collapse_menu.svg" />
  </button>
  <app-header class="onlyMobile"></app-header>

  <div class="containerLogo">
    <img src="{{ logoCliente }}" alt="logo" class="logo" />
  </div>

  <div class="itens h-100">
    <button
      class="item clickable"
      [ngClass]="{ active: rotaAtual({ link: '/home' }) }"
      (click)="getRotaHome()"
    >
      <i class="aviva-feather-home aviva-margin-right-nano"></i>
      <p>Home</p>
    </button>
    <div class="submenus"></div>
    <ng-container *ngFor="let menu of menus">
      <button class="item" [ngClass]="{ active: rotaAtual(menu.menus) }">
        <i class="aviva-feather-{{ menu.icone }} aviva-margin-right-nano"></i>
        <p>{{ menu.descricao }}</p>
      </button>
      <div class="submenus">
        <button
          class="item"
          *ngFor="let submenu of menu.menus"
          [ngClass]="{ active: rotaAtual(submenu) }"
          [routerLink]="['/operacional/' + submenu.link]"
        >
          <p>{{ submenu.descricao }}</p>
        </button>
      </div>
    </ng-container>
  </div>
  <div class="itens logout onlyMobile">
    <button class="item" (click)="confirmarLogout()">
      <i class="aviva-feather-log-out aviva-margin-right-nano"></i>
      <p>Sair</p>
    </button>
  </div>
</aside>

<app-modal-alert
  *ngIf="exibirModalConfirmacao"
  [options]="optionsModal"
  (eventButtonLeft)="closeModal()"
  (eventButtonRight)="logout()"
>
</app-modal-alert>
