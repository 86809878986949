import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() public image: string;
  @Input() public name: string;
  @Input() public marginRight: boolean = true;
  @Input() public colorPrimaria: string;

  public showIniciais = false;
  public iniciais: string;
  public background: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.image) {
      this.showIniciais = true;
      this.createIniciais();

      this.background = this.colorPrimaria;
    }
  }

  private stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  private createIniciais() {
    let iniciais = '';

    let nomes = this.name.split(' ');

    let firstName = nomes[0];
    let lastName = nomes[nomes.length - 1];

    iniciais += firstName.toUpperCase().charAt(0);
    iniciais += lastName.toUpperCase().charAt(0);

    this.iniciais = iniciais;
  }
}
