import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { StorageService } from '../../_services/storage/storage.service';
import {
  ModalAlertOptions,
  TypeModalAlert,
} from '../modal-alert/models/modal-alert.model';
import { SharedService } from './../../_services/shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public id = 0;
  public nome: string = '';
  public email: string = '';
  public dataAtual: string = '';
  public horaAtual: string = '';
  public colorPrimaria: string = '';
  public clienteVinculado: string = '';
  public optionsModal: ModalAlertOptions = new ModalAlertOptions();
  public exibirModalConfirmacao: boolean = false;
  public horaMinutoAtual: string = '';
  public logoCliente: any;

  constructor(
    public _serviceShared: SharedService,
    public _serviceStorage: StorageService,
    private _router: Router
  ) {}

  ngAfterViewInit(): void {
    this.logoCliente = this.carregarLogo();
  }

  ngOnInit(): void {
    this.id = Number(this._serviceStorage.getIdUsuario());
    this.nome =
      this._serviceStorage.getNome() == 'null'
        ? 'Administrador'
        : this._serviceStorage.getNome();
    this.email = this._serviceStorage.getEmail();
    this.dataAtual = this._serviceStorage.getDataAtual();
    this.horaAtual = this._serviceStorage.getHoraAtual();
    this.horaMinutoAtual = this.getHoraMinuto(this.horaAtual);
    this.clienteVinculado = this._serviceStorage.getClienteVinculado();
  }

  mudarMenu() {
    this._serviceShared.handleMenu(!this._serviceShared.exibirMenu);
  }

  gerarModalConfirmacao() {
    this.optionsModal.title = 'Sair do sistema';
    this.optionsModal.message = 'Tem certeza que deseja sair do sistema?';
    this.optionsModal.buttonRight = 'Sair';
    this.optionsModal.buttonLeft = 'Cancelar';
    this.optionsModal.type = TypeModalAlert.NEUTRAL;
    this.optionsModal.icon = 'aviva-feather-log-out';

    this.exibirModalConfirmacao = true;
  }

  playSound() {
    let audio = new Audio();
    audio.src = '/assets/audios/notification.wav';
    audio.load();
    audio.play();
  }

  getHoraMinuto(horaAtual: string) {
    if (!!horaAtual) {
      const timeObject = moment(horaAtual, 'HH:mm');
      const hour = timeObject.format('HH');
      const minute = timeObject.format('mm');
      return `${hour}:${minute}`;
    } else {
      return horaAtual;
    }
  }

  carregarLogo(): string {
    const logoWhiteLabel = this._serviceStorage.LOGO_BASE64();
    if (!!logoWhiteLabel && logoWhiteLabel !== 'undefined') {
      return logoWhiteLabel.replace(/['"]/g, '');
    } else {
      return '/assets/logos/icon_cdr.svg';
    }
  }
}
