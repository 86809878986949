import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../header/header.module';
import { ModalAlertModule } from '../modal-alert/modal-alert.module';
import { MenuLateralComponent } from './menu-lateral.component';

@NgModule({
  declarations: [MenuLateralComponent],
  imports: [CommonModule, RouterModule, ModalAlertModule, HeaderModule],
  exports: [MenuLateralComponent],
})
export class MenuLateralModule {}
