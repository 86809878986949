export class ModalAlertOptions {
    constructor(
        public title: string = '',
        public message: string = '',
        public buttonLeft: string = '',
        public buttonRight: string = '',
        public type: number = TypeModalAlert.NEUTRAL,
        public icon: string | null = '',
    ) { }
}

export enum TypeModalAlert {
    NEUTRAL,
    SUCCESS,
    ERROR,
    PRIMARY
}
