import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { StorageService } from '../storage/storage.service';

import { RoleFuncionalidadeAcaoPerfilQuery } from '../../_models/role.model';
import { BaseResponse } from '../../_models/response.model';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(
    private http: HttpClient,
    private _serviceStorage: StorageService
  ) {}

  validarFuncionalidadeAcao(data) {
    let request = new RoleFuncionalidadeAcaoPerfilQuery(
      data.idFuncionalidade,
      data.idAcao,
      this._serviceStorage.getIdUsuario()
    );

    return this.http
      .post(`${environment.API}/funcionalidade-acao-perfil/validar`, request)
      .pipe(map((response: BaseResponse) => response.data));
  }
}
