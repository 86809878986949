import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/core/_models/response.model';
import { environment } from 'src/environments/environment';
import {
  AdicionarUsuarioCommand,
  EditarUsuarioCommand,
  FiltrarUsuariosQuery,
  MultSelectVendedoresRequest,
  VerificarUsuarioAtivoQuery,
} from '../models/usuario.model';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(private http: HttpClient, private _router: Router) {}

  listar() {
    return this.http
      .get(`${environment.API}/usuario`)
      .pipe(map((response: BaseResponse) => response.data));
  }

  obter(id: number) {
    return this.http
      .get(`${environment.API}/usuario/${id}`)
      .pipe(map((response: BaseResponse) => response.data));
  }

  adicionar(request: AdicionarUsuarioCommand) {
    return this.http
      .post(`${environment.API}/usuario`, request)
      .pipe(map((response: BaseResponse) => response.data));
  }

  editar(request: EditarUsuarioCommand) {
    return this.http
      .put(`${environment.API}/usuario`, request)
      .pipe(map((response: BaseResponse) => response.data));
  }

  reenviarEmail(id: number) {
    return this.http.post(`${environment.API}/usuario/reenviar-email`, { id });
  }

  verificarUsuarioAtivo(request: VerificarUsuarioAtivoQuery) {
    return this.http
      .post(`${environment.API}/usuario/usuario-ativo/`, request)
      .pipe(map((response: BaseResponse) => response.data));
  }

  filtrar(request: FiltrarUsuariosQuery) {
    return this.http
      .post(`${environment.API}/usuario/filtrar`, request)
      .pipe(map((response: BaseResponse) => response.data));
  }

  exportarCSV(request: FiltrarUsuariosQuery) {
    return this.http.post(
      `${environment.API}/usuario/filtrar/exportarexcel`,
      request,
      { responseType: 'blob' }
    );
  }

  filtrarVendedores(request: MultSelectVendedoresRequest) {
    return this.http
      .post(
        `${environment.API}/usuario/filtrar-multiselect-vendedores`,
        request
      )
      .pipe(map((response: BaseResponse) => response.data));
  }

  obterMeusDados() {
    return this.http
      .get(`${environment.API}/usuario/obter-meus-dados`)
      .pipe(map((response: BaseResponse) => response.data));
  }
}
