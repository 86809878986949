import { createAction, props } from "@ngrx/store";
import { UsuarioGridResponse } from "../models/usuario.model";

export const LOAD_USUARIOS = '[Usuario] Load Usuario';
export const LOAD_USUARIOS_SUCCESS = '[Usuario] Load Usuario Success';

export const loadUsuarios = createAction(LOAD_USUARIOS);
export const loadUsuariosSuccess = createAction(
  LOAD_USUARIOS_SUCCESS,
  props<{ usuarios: UsuarioGridResponse[] }>()
);