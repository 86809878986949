import { Component, OnInit } from '@angular/core';
import { ProcessFileService } from './_services/process-file.service';

@Component({
  selector: 'process-file',
  templateUrl: './process-file.component.html',
  styleUrls: ['./process-file.component.scss'],
})
export class ProcessFileComponent implements OnInit {
  constructor(private _processFileService: ProcessFileService) {}
  showLoading: boolean = false;
  ngOnInit(): void {
    this._processFileService.getLoadingStatus().subscribe((value) => {
      this.showLoading = value;
    });
  }
}
