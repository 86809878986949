import { Component, OnInit } from '@angular/core';
import { HttpStatus } from './_services/http-status/http-status.service';
import { HttpUtils } from './_services/http-utils/http-utils.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  public exibir = false;
  public desabilitaLoading = false;

  public timeout: any;

  constructor(private _httpStatus: HttpStatus, private _httpUtils: HttpUtils) {
    this._httpUtils.HideLoading.subscribe((hide) => (this.exibir = !hide));

    this._httpUtils.DesabilitaLoading.subscribe(
      (disable) => (this.desabilitaLoading = disable)
    );

    this._httpStatus.getHttpStatus().subscribe((status: boolean) => {
      if (!status) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (_httpStatus.exibirLoading) {
            this._httpUtils.forceLoadingUpdateHide(status);
          }
        }, 50);
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this._httpUtils.forceLoadingUpdateHide(true);
        }, 50);
      }
    });
  }

  ngOnInit(): void {}
}
