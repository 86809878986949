import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalAlertOptions } from './models/modal-alert.model';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {

  @Input() options: ModalAlertOptions = new ModalAlertOptions();

  @Output() eventButtonLeft = new EventEmitter();
  @Output() eventButtonRight = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clickButtonLeft() {
    this.eventButtonLeft.emit('');
  }

  clickButtonRight() {
    this.eventButtonRight.emit('');
  }

  getBackgroundColor() {
    switch (this.options.type) {
      case 1:
        return `aviva-bg-accent-200`;
      case 2:
        return `aviva-bg-negative-200`;
      case 3:
        return `aviva-bg-primary-100`;
      default:
        return ``;
    }
  }
  getFontColor() {
    switch (this.options.type) {
      case 1:
        return 'aviva-color-accent-200';
      case 2:
        return 'aviva-color-negative-200';
      case 3:
        return 'aviva-color-primary-100';
      default:
        return '';
    }
  }
}
