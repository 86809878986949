export enum eAcoes {
  Consultar = 1,
  Cadastrar = 2,
  Editar = 3,
  Deletar = 4,
  GestaoVendas = 5,
  GestaoComissaoPropria = 6,
  GestaoComissaoHierarquica = 7,
  GestaoVendasHierarquica = 8,
}

export enum eLogAcoes {
  Consultar = 1,
  Cadastrar = 2,
  Editar = 3,
  Deletar = 4,
  GestaoVendas = 5,
  Acessar = 6,
}

export enum eLogTelas {
  Login = 1,
  Home = 2,
  GradeParceiros = 3,
  GradeOfertas = 4,
  GradeProdutos = 5,
  DetalhesProduto = 6,
  TermosDeUsoEPoliticaDePrivacidade = 7,
}

export enum eFuncionalidades {
  CadastroUsuario = 1,
  CadastroPerfil = 2,
  CadastroEntidade = 3,
  CadastroParceiro = 4,
  CadastroProdutosServicos = 5,
  CadastroContato = 6,
  CadastroCliente = 7,
  CadastroComissao = 8,
  CadastroVenda = 9,
  CadastroControlePosVenda = 10,
  CadastroRelatorioPosVenda = 11,
  CadastroOferta = 12,
  CadastroCatalogoComercial = 13,
  CadastroMinhaConta = 14,
  CadastroMeusInteresses = 15,
  CadastroMinhaCompra = 16,
  CadastroFunilVenda = 17,
  CadastroTemplate,
  //GERADOR-TELAS
}

export enum eStatus {
  Indefinido = 0,
  Ativo = 1,
  Inativo = 2,
}

export enum eEscopo {
  Indefinido = 0,
  Fechado = 1,
  Aberto = 2,
}

export class RoleFuncionalidadeAcaoQuery {
  constructor(public idFuncionalidade: number, public idAcao: number) {}
}

export class ValidarFuncionalidadeAcaoPerfilQuery {
  constructor(
    public idFuncionalidade: number,
    public idAcao: number,
    public idUsuario: string
  ) {}
}

export enum eTipoCliente {
  CentralDeRede = 1,
  Confederacao = 3,
  Federacao = 4,
  Associacao = 5,
  Parceiro = 2,
  Cliente = 6,
}
