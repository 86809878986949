<div class="aviva-modal-background"></div>
<div class="aviva-modal" [class.confirmation]="options.buttonLeft">
    <i class="{{ options.icon }}" [ngClass]="getFontColor()"></i>
    <p class="title {{ options.icon ? 'aviva-margin-top-xs' : '' }}" [ngClass]="getFontColor()">
        {{
        options.title.length > 50
        ? (options.title | slice : 0 : 50) + "..."
        : options.title
        }}
    </p>
    <p class="body">{{ options.message }}</p>

    <div class="buttons aviva-margin-top-sm">
        <div class="order-first">
            <button *ngIf="options.buttonLeft" type="button" class="aviva-button-neutral-low" (click)="clickButtonLeft()"
                [ngClass]="getFontColor()">
                {{ options.buttonLeft }}
            </button>
        </div>
        <div class="order-last">
            <button *ngIf="options.buttonRight" type="button"
                class="aviva-button-neutral-high buttonsResponsive"
                [ngClass]="getBackgroundColor()" (click)="clickButtonRight()">
                {{ options.buttonRight }}
            </button>
        </div>
    </div>
</div>