<header class="aviva-header">
  <div class="header-left">
    <button
      class="button-icon logo aviva-margin-right-xxs onlyWeb"
      *ngIf="!_serviceShared.exibirMenu"
    >
      <img class="logo" src="{{ logoCliente }}" alt="logo" />
    </button>
    <button
      class="button-icon aviva-margin-right-xxs"
      *ngIf="!_serviceShared.exibirMenu"
      (click)="mudarMenu()"
    >
      <i class="aviva-feather-menu"></i>
    </button>

    <button
      class="button-icon aviva-margin-right-xxs"
      *ngIf="_serviceShared.exibirMenu"
      (click)="mudarMenu()"
    >
      <i class="aviva-feather-menu"></i>
    </button>
  </div>

  <div class="header-right">
    <div class="info-text">
      <div class="onlyWeb">
        <b>{{ nome }}</b> <span> • </span>{{ email }} <span> • </span>
        {{ dataAtual }} <span> • </span> {{ horaAtual }}
      </div>
      <div class="info-mobile onlyMobile">
        <p class="info-name overflowText">
          <b>{{ nome }}</b>
        </p>
        <p>{{ email }}</p>
        <p>{{ dataAtual }} {{ horaAtual }}</p>
      </div>
    </div>
    <div>
      <app-menu-dropdown
        [nome]="nome"
        [email]="email"
        [dataAtual]="dataAtual"
        [horaAtual]="horaMinutoAtual"
        [clienteVinculado]="clienteVinculado"
      ></app-menu-dropdown>
    </div>
  </div>
</header>
