import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '../avatar/avatar.module';
import { MenuDropdownModule } from '../menu-dropdown/menu-dropdown.module';
import { ModalAlertModule } from '../modal-alert/modal-alert.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    AvatarModule,
    ModalAlertModule,
    MenuDropdownModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
