export class Toast {
   constructor(
        public type: number = ToastType.SUCCESS,
        public message: string = '',
        public button: string = '',
        public closeable: boolean = false,
        public keepAfterRouteChange: boolean = false,
        public routeDefault: string = '',
        public routeAction: string = ''
   ) {}
}

export enum ToastType {
    SUCCESS,
    ERROR,
    INFO,
    NEUTRAL,
    ETHERNET
}