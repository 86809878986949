<div class="divfixed">
    <div class="alert alertToast">
        <div *ngFor="let t of toasts" class="aviva-toast {{ getClass(t) }} aviva-margin-bottom-xxxs"
            [ngClass]="{ action: t.button }">
            <div class="toastRow">
                <em *ngIf="t.type == 0" class="aviva-feather-check-circle"></em>
                <em *ngIf="t.type == 1" class="aviva-feather-alert-circle"></em>
                <em *ngIf="t.type == 2" class="aviva-icon-info"></em>
                <em *ngIf="t.type == 4" class="aviva-feather-wifi-off"></em>
                <span class="text" [innerHTML]="t.message"></span>
            </div>
            <button *ngIf="t.button" class="action" (click)="clickButton(t)">
                {{ t.button }}
            </button>
            <em *ngIf="t.closeable" class="aviva-icon-close" (click)="closeToast(t)"></em>
        </div>
    </div>
</div>