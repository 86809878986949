import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { loadUsuarios, loadUsuariosSuccess } from './usuario.actions';
import { UsuarioService } from '../services/usuario.service';
import { UsuariosGridResponse, FiltrarUsuariosQuery } from '../models/usuario.model';
import { eStatus } from 'src/app/core/guards/role/_models/role.model';

@Injectable()
export class UsuarioEffects {
    constructor(
        private actions$: Actions,
        private usuarioService: UsuarioService
    ) {}
    
    loadUsuarios$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadUsuarios),
            mergeMap((action) => {
                return [];

                // return this.usuarioService.filtrar(new FiltrarUsuariosQuery('', 0, eStatus.Ativo, 0, 0)).pipe(
                //     map((res: UsuariosGridResponse) => {
                //         return loadUsuariosSuccess({ usuarios: res.rows });
                //     })
                // );
                
            })
        );
    });
}