import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../_services/storage/storage.service';
import { ConnectionService } from '../_services/connection/connection.service';
import { ToastService } from '../components/toast/_services/toast.service';

@Injectable()
export class InterceptorHttp implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _serviceStorage: StorageService,
    private _connectionService: ConnectionService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._serviceStorage.getToken();

    if (request.url.startsWith('https://viacep.com.br/ws')) {
      return next.handle(request);
    }

    if (token) {
      const clonedRequest = request.clone({
        headers: request.headers.set('authorization', `bearer ${token}`),
      });

      return next.handle(clonedRequest).pipe(
        map((event) => {
          return event;
        }),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._router.navigate(['/sign-in']);
            } else if (err.status === 0) {
              this._connectionService.error();
            }
          }

          return throwError(err);
        })
      );
    }

    return next.handle(request);
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorHttp,
      multi: true,
    },
  ],
})
export class Interceptor {}
