import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/core/_models/response.model';
import { StorageService } from 'src/app/core/_services/storage/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModalTermosUsoPoliticaPrivacidadeService {
  private subject = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private _storageService: StorageService
  ) {}

  getModal(): Observable<any> {
    return this.subject.asObservable();
  }
  setModal(bol: boolean) {
    this.subject.next(bol);
  }

  setPoliticaDePrivacidade(boolean) {
    this._storageService.setTermoDeUso(boolean);
    return this.http
      .post(`${environment.API}/usuario/gravar-politica-privacidade`, {
        politicaAceita: boolean,
      })
      .pipe(map((response: BaseResponse) => response.data));
  }
}
