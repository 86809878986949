<app-loading></app-loading>
<modal-termos-uso-politica-privacidade></modal-termos-uso-politica-privacidade>
<app-menu-lateral
  *ngIf="exibirControles && _serviceShared.exibirMenu"
></app-menu-lateral>

<div [ngClass]="{ main: exibirControles, reduzido: _serviceShared.exibirMenu }">
  <app-header *ngIf="exibirControles"></app-header>

  <div class="content" [ngClass]="{ controls: exibirControles }">
    <router-outlet></router-outlet>
  </div>

  <app-footer *ngIf="exibirControles"></app-footer>
</div>

<app-toast></app-toast>
<process-file></process-file>
