import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HttpStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  public contadorRequisitions = 0;

  public exibirLoading: boolean = false;
  public exibirLoadingNotification: boolean = false;

  constructor() {
    this.requestInFlight$ = new BehaviorSubject(true);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}
