export class TipoCliente {
  constructor(
    public id: number,
    public descricao: string,
    public url: string | null
  ) {}
}

export const TiposClienteConstant: Array<TipoCliente> = new Array<TipoCliente>(
  { id: 1, descricao: 'Central de Rede', url: 'cdr' },
  { id: 2, descricao: 'Parceiro', url: 'parceiro' },
  { id: 3, descricao: 'Confederação', url: 'confederacao' },
  { id: 4, descricao: 'Federação', url: 'federacao' },
  { id: 5, descricao: 'Associação', url: 'associacao' },
  { id: 6, descricao: 'Associado', url: null },
  { id: 7, descricao: 'Todos', url: null },
  { id: 8, descricao: 'Cliente', url: 'cliente' }
);

export const TiposClienteConstantAtualizado: Array<TipoCliente> =
  new Array<TipoCliente>(
    { id: 1, descricao: 'Central de Rede', url: 'cdr' },
    { id: 2, descricao: 'Parceiro', url: 'parceiro' },
    { id: 3, descricao: 'Confederação', url: 'confederacao' },
    { id: 4, descricao: 'Federação', url: 'federacao' },
    { id: 5, descricao: 'Associação', url: 'associacao' },
    { id: 8, descricao: 'Cliente', url: null },
    { id: 7, descricao: 'Todos', url: null }
  );
