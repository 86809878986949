import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Toast, ToastType } from '../_models/toast.model';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private subject = new Subject<Toast>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {}

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(
    message: string,
    button = '',
    keepAfterRouteChange = false,
    closeable: boolean = false,
    routeDefault: string = '',
    routeAction: string = ''
  ) {
    this.alert(
      ToastType.SUCCESS,
      message,
      button,
      keepAfterRouteChange,
      closeable,
      routeDefault,
      routeAction
    );
  }

  error(
    message: string,
    button = '',
    keepAfterRouteChange = false,
    closeable: boolean = false,
    routeDefault: string = '',
    routeAction: string = ''
  ) {
    this.alert(
      ToastType.ERROR,
      message,
      button,
      keepAfterRouteChange,
      closeable,
      routeDefault,
      routeAction
    );
  }

  info(
    message: string,
    button = '',
    keepAfterRouteChange = false,
    closeable: boolean = false,
    routeDefault: string = '',
    routeAction: string = ''
  ) {
    this.alert(
      ToastType.INFO,
      message,
      button,
      keepAfterRouteChange,
      closeable,
      routeDefault,
      routeAction
    );
  }

  neutral(
    message: string,
    button = '',
    keepAfterRouteChange = false,
    closeable: boolean = false,
    routeDefault: string = '',
    routeAction: string = ''
  ) {
    this.alert(
      ToastType.NEUTRAL,
      message,
      button,
      keepAfterRouteChange,
      closeable,
      routeDefault,
      routeAction
    );
  }

  connection(
    message: string,
    button = '',
    keepAfterRouteChange = false,
    closeable: boolean = false,
    routeDefault: string = '',
    routeAction: string = ''
  ) {
    this.alert(
      ToastType.ETHERNET,
      message,
      button,
      keepAfterRouteChange,
      closeable,
      routeDefault,
      routeAction
    );
  }

  alert(
    type: ToastType,
    message: string,
    button = '',
    keepAfterRouteChange = false,
    closeable: boolean,
    routeDefault: string = '',
    routeAction: string = ''
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Toast>{
      type: type,
      message: message,
      button: button,
      closeable: closeable,
      routeDefault: routeDefault,
      routeAction: routeAction,
    });
  }

  clear() {
    // clear alerts
    this.subject.next(new Toast());
  }
}
