import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TiposClienteConstant } from '../../_models/tipo-cliente.models';
import { SharedService } from '../../_services/shared/shared.service';
import { StorageService } from '../../_services/storage/storage.service';
import {
  ModalAlertOptions,
  TypeModalAlert,
} from '../modal-alert/models/modal-alert.model';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent implements OnInit {
  @Input() nome: string = '';
  @Input() email: string = '';
  @Input() dataAtual: string = '';
  @Input() horaAtual: string = '';
  @Input() clienteVinculado: string = '';
  public optionsModal: ModalAlertOptions = new ModalAlertOptions();
  public exibirModalConfirmacao: boolean = false;

  constructor(
    public _serviceStorage: StorageService,
    public _serviceShared: SharedService,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  confirmarLogout() {
    this.gerarModalConfirmacao();
  }

  gerarModalConfirmacao() {
    this.optionsModal.title = 'Sair do sistema';
    this.optionsModal.message = 'Tem certeza que deseja sair do sistema?';
    this.optionsModal.buttonRight = 'Sair';
    this.optionsModal.buttonLeft = 'Cancelar';
    this.optionsModal.type = TypeModalAlert.NEUTRAL;
    this.optionsModal.icon = 'aviva-feather-log-out';

    this.exibirModalConfirmacao = true;
  }

  logout() {
    const usuarioTipoCliente: number | null =
      !!this._serviceStorage.getTipoCliente()
        ? Number(this._serviceStorage.getTipoCliente())
        : 1;
    const rotaLogin: string = `/sign-in/${
      TiposClienteConstant.find((item) => item.id === usuarioTipoCliente).url
    }`;

    this._serviceShared.handleMenu(true);

    this._router.navigate([rotaLogin]);
  }

  closeModal() {
    this.exibirModalConfirmacao = false;
  }

  redirectMinhaConta() {
    this._router.navigate(['/operacional/minha-conta']);
  }

  redirectDropDown(url: string) {
    window.open(url, '_blank');
  }
}
