import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { HttpStatus } from '../http-status/http-status.service';

@Injectable()
export class HttpListener implements HttpInterceptor {

  constructor(
    private status: HttpStatus
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        this.status.exibirLoading = false;
        this.status.exibirLoadingNotification = false;

        if(req.url.search('/filtrar') >= 0) {
          this.status.exibirLoading = false;
        } else if(req.url.search('/Notificacao') >= 0) {
          this.status.exibirLoadingNotification = true;
        } else {
          this.status.exibirLoading = true;
        }

        if(req.url.search('/qtd') >= 0) {
          this.status.exibirLoadingNotification = false;
        }

        if (event.type === 0) {
          this.status.contadorRequisitions++;
          this.status.setHttpStatus(false);
        }
        return event;
      }),
      finalize(() => {
        this.status.contadorRequisitions = this.status.contadorRequisitions <= 1 ? 0 : this.status.contadorRequisitions - 1;
        if (this.status.contadorRequisitions === 0) {
          this.status.setHttpStatus(true);
        }
      })
    );
  }
}
