import { Injectable } from '@angular/core';
import { RoleFuncionalidadeAcaoQuery } from '../../guards/role/_models/role.model';
import { UsuarioStorage } from '../../_models/storage.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public getUser(): UsuarioStorage {
    if (!this.getIdUsuario()) {
      return null;
    }

    return new UsuarioStorage(
      this.getIdUsuario(),
      this.getNome(),
      this.getEmail(),
      this.getIdPerfil(),
      this.getIdCliente(),
      this.getTipoCliente(),
      this.getToken(),
      [],
      this.getFuncionalidades(),
      this.getCorPrimaria(),
      this.getCorSecundaria(),
      this.LOGO_BASE64(),
      this.getTermoDeUso(),
      this.getClienteVinculado(),
      this.getHoraAtual(),
      this.getDataAtual()
    );
  }

  public getIdUsuario(): string {
    return localStorage.getItem('ID_USUARIO')
      ? localStorage.getItem('ID_USUARIO')
      : null;
  }

  public getNome(): string {
    return localStorage.getItem('NOME')
      ? atob(localStorage.getItem('NOME'))
      : null;
  }

  public getEmail(): string {
    return localStorage.getItem('EMAIL')
      ? atob(localStorage.getItem('EMAIL'))
      : null;
  }

  public getIdCliente(): string {
    return localStorage.getItem('ID_CLIENTE')
      ? localStorage.getItem('ID_CLIENTE')
      : null;
  }

  public getIdPerfil(): string {
    return localStorage.getItem('ID_PERFIL')
      ? atob(localStorage.getItem('ID_PERFIL'))
      : null;
  }

  public getTipoCliente(): string {
    return localStorage.getItem('TIPO_CLIENTE')
      ? atob(localStorage.getItem('TIPO_CLIENTE'))
      : null;
  }

  public getToken(): string {
    return localStorage.getItem('TOKEN')
      ? atob(localStorage.getItem('TOKEN'))
      : null;
  }

  public getMenus(): string {
    return localStorage.getItem('MENUS')
      ? JSON.parse(atob(localStorage.getItem('MENUS')))
      : [];
  }

  public getFuncionalidades(): any {
    return localStorage.getItem('FUNCIONALIDADES')
      ? JSON.parse(atob(localStorage.getItem('FUNCIONALIDADES')))
      : [];
  }

  public getCorPrimaria(): string {
    return localStorage.getItem('COR_PRIMARIA')
      ? atob(localStorage.getItem('COR_PRIMARIA'))
      : null;
  }

  public getCorSecundaria(): string {
    return localStorage.getItem('COR_SECUNDARIA')
      ? atob(localStorage.getItem('COR_SECUNDARIA'))
      : null;
  }

  public LOGO_BASE64(): string {
    return localStorage.getItem('LOGO_BASE64')
      ? atob(localStorage.getItem('LOGO_BASE64'))
      : null;
  }

  public verificarFuncionalidade(permissao: RoleFuncionalidadeAcaoQuery) {
    let funcionalidade = null;
    let listaFuncionalidades = this.getFuncionalidades();
    funcionalidade = listaFuncionalidades.find(
      (x) => x.id === permissao.idFuncionalidade
    );
    if (funcionalidade?.acoes?.length) {
      if (funcionalidade.acoes.find((x) => x.id === permissao.idAcao)) {
        return true;
      }
    } else {
      return false;
    }
    return false;
  }
  public getHash(): string {
    return localStorage.getItem('HASHID')
      ? localStorage.getItem('HASHID')
      : null;
  }

  public getTermoDeUso(): boolean {
    return localStorage.getItem('TERMOS_USO_ACEITO')
      ? JSON.parse(atob(localStorage.getItem('TERMOS_USO_ACEITO')))
      : null;
  }
  public setTermoDeUso(termo: boolean) {
    localStorage.setItem('TERMOS_USO_ACEITO', btoa(JSON.stringify(termo)));
  }

  public getDataAtual(): string {
    return localStorage.getItem('DATAATUAL')
      ? atob(localStorage.getItem('DATAATUAL'))
      : null;
  }

  public getHoraAtual(): string {
    return localStorage.getItem('HORAATUAL')
      ? atob(localStorage.getItem('HORAATUAL'))
      : null;
  }

  public getColorPrimaria(): string {
    return localStorage.getItem('COR_PRIMARIA')
      ? atob(localStorage.getItem('COR_PRIMARIA'))
      : null;
  }

  public getColorSecundaria(): string {
    return localStorage.getItem('COR_SEGUNDARIA')
      ? atob(localStorage.getItem('COR_SEGUNDARIA'))
      : null;
  }

  public getClienteVinculado(): string {
    return localStorage.getItem('RAZAOSOCIALCLIENTEVINCULADO')
      ? atob(localStorage.getItem('RAZAOSOCIALCLIENTEVINCULADO'))
      : null;
  }

  public setUser(usuario: UsuarioStorage): void {
    localStorage.setItem('ID_USUARIO', usuario.ID_USUARIO);
    localStorage.setItem('NOME', btoa(usuario.NOME));
    localStorage.setItem('EMAIL', btoa(usuario.EMAIL));
    localStorage.setItem('ID_CLIENTE', usuario.ID_CLIENTE);
    localStorage.setItem('TIPO_CLIENTE', btoa(usuario.TIPO_CLIENTE));
    localStorage.setItem('ID_PERFIL', btoa(usuario.ID_PERFIL));
    localStorage.setItem('TOKEN', btoa(usuario.TOKEN));
    localStorage.setItem('MENUS', btoa(JSON.stringify(usuario.MENUS)));
    localStorage.setItem(
      'FUNCIONALIDADES',
      btoa(JSON.stringify(usuario.FUNCIONALIDADES))
    );
    localStorage.setItem(
      'COR_PRIMARIA',
      btoa(JSON.stringify(usuario.COR_PRIMARIA))
    );
    localStorage.setItem(
      'COR_SECUNDARIA',
      btoa(JSON.stringify(usuario.COR_SECUNDARIA))
    );
    localStorage.setItem(
      'LOGO_BASE64',
      btoa(JSON.stringify(usuario.LOGO_BASE64))
    );
    localStorage.setItem(
      'TERMOS_USO_ACEITO',
      btoa(JSON.stringify(usuario.TERMOS_USO_ACEITO))
    );
    localStorage.setItem(
      'RAZAOSOCIALCLIENTEVINCULADO',
      btoa(usuario.RAZAOSOCIALCLIENTEVINCULADO)
    );
    localStorage.setItem('DATAATUAL', btoa(usuario.DATAATUAL));
    localStorage.setItem('HORAATUAL', btoa(usuario.HORAATUAL));
  }

  public clear(): void {
    localStorage.clear();
  }

  public aplicarWhiteLabel(): void {
    const user = this.getUser();

    if (!!user?.COR_PRIMARIA && user?.COR_PRIMARIA !== 'undefined') {
      document.documentElement.style.setProperty(
        '--corCaixa1',
        user?.COR_PRIMARIA.replace(/"/g, '')
      );
    }

    if (!!user?.COR_SECUNDARIA && user?.COR_SECUNDARIA !== 'undefined') {
      document.documentElement.style.setProperty(
        '--corCaixa2',
        user?.COR_SECUNDARIA.replace(/"/g, '')
      );
    }
  }
}
