import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/operacional/home',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./modules/access-control/sign-in/sign-in.module').then(m => m.SignInModule)
  },
  {
    path: 'sign-in/:tipo',
    loadChildren: () => import('./modules/access-control/sign-in/sign-in.module').then(m => m.SignInModule)
  },
  {
    path: 'esqueci-senha', //recuperar-senha
    loadChildren: () => import('./modules/access-control/esqueci-senha/esqueci-senha.module').then(m => m.EsqueciSenhaModule)
  },
  {
    path: 'definir-senha/:token',
    loadChildren: () => import('./modules/access-control/definir-senha/definir-senha.module').then(m => m.DefinirSenhaModule)
  },
  {
    path: 'cadastrar-senha/:token',
    loadChildren: () => import('./modules/access-control/cadastrar-senha/cadastrar-senha.module').then(m => m.CadastrarSenhaModule)
  },
  {
    path: 'operacional',
    loadChildren: () => import('./routes/operacional/operacional.module').then(m => m.OperacionalModule)
  },
  {
    path: 'erros',
    loadChildren: () => import('./modules/errors/error-404/error-404.module').then(m => m.Error404Module)
  },
  {
    path: '**',
    redirectTo: 'erros/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
