<div class="divfixed" *ngIf="showLoading">
  <div class="background-process-files"></div>
  <div class="div-center">
    <div class="div-warining">
      <i class="aviva-feather-loader icn-spinner"></i>

      <p class="div-warning-text">
        Arquivo em processamento... Não sendo possível nenhuma outra ação no
        momento.
      </p>
    </div>
  </div>
</div>
