import { createReducer, on } from "@ngrx/store";
import { UsuarioGridResponse } from "../models/usuario.model";
import { loadUsuariosSuccess } from "./usuario.actions";

export interface UsuarioState {
    usuarios: UsuarioGridResponse[]
};

export const initialState: UsuarioState = {
    usuarios: []
};

const _usuarioReducer = createReducer(
    initialState,
    on(loadUsuariosSuccess, (state, action) => {
        return {
            ...state,
            usuarios: action.usuarios,
        };
    })
);

export function usuarioReducer(state, action) {
    return _usuarioReducer(state, action);
}